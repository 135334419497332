body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: auto !important;
}


.App {
  display: flex;
  flex-direction: column;
  display:flex;
}

.col-md-7, .col-md-5, .col-md-12 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.cover-spin {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.95);
  z-index:9999;
}

@-webkit-keyframes spin {
from {-webkit-transform:rotate(0deg);}
to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
from {transform:rotate(0deg);}
to {transform:rotate(360deg);}
}

.cover-spin::after {
  content:'';
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:40px;height:40px;
  border-style:solid;
  border-color: #ff0000;
  border-top-color:transparent;
  border-width: 4px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

h3 {
  font-size: 1.3rem !important;
}

p {
  margin-bottom: .5rem !important;
}

.popup .notdienst {
  background-color: #efefef;
  padding: 10px;
}

.notdienst span {
  background-color: #ff0000;
  color: #FFFFFF;
  padding: 2px 10px;
  display: inline-block;
}

.pharmacy {
  position: relative;
  clear: both;
  display: block;
  width: 100%;
  padding: 1rem;
}
.pharmacy .pharmacy-info {
  display: none;
}

.pharmacy .phone {
  font-size: 1.1rem;
  padding-top: 3px;
  vertical-align:bottom;
  line-height: 1.35;
}

.pharmacy .phone:hover {
  color: rgb(59, 166, 59);
  text-decoration: none;
}

.pharmacy.active {
  background: #ededee;
}

.pharmacy.active .pharmacy-info {
  display: block;
  margin-top: 1rem;
  background: #ededee;
  width: 100%;
  padding: 1rem;
}

.pharmacy.active .pharmacy-info a {
  color: #ededee;
}

.pharmacy .head .title {
  font-size: 1.2rem !important;
  font-weight: 600;
}

.dflex {
  display: flex;
}

.form {
  padding: 15px 0;
  background: #FFFFFF;
  border: 2px solid #efefef;
  position: sticky;
  top: 0;
  z-index: 999;
}

#map {
  width: 50%;
  height: calc(100vh - 122px);
}

.content {
  width: 50%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100vh - 122px);
}

.content::-webkit-scrollbar {
  display: none;
}

.km {
  text-align: center;
}

.col-left {
  width: 80%;
  float: left;
}

.col-right {
  width: 20%;
  text-align: right;
  float: left;
}

.btn-danger, .btn-danger:active, .btn-danger:focus, .btn-danger:visited {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.btn-primary, .btn-primary:active, .btn-primary:focus, .btn-primary:visited {
  background-color: #74777C !important;
  border-color: #74777C !important;
}

.btn:focus, .btn:visited {
  outline: 0;
}


.btn-primary {
  margin-top: 1rem;
}

.pharmacy a, .popup a {
  color: #1d2124;
}
.pharmacy a:hover, .popup a:hover {
  color: #ff0000;
}

.head .title {
  cursor: pointer;
}

.date-list {
  margin-bottom: 2px;
}

.date-list.active .box {
  display: block;
}

.date-list .box {
  display: none;
}

.date-list .date {
  background-image: url('../images/icon-downarrow.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: right 15px center ;
}
.date-list.active .date {
  background-image: url('../images/icon-uparrow.png');
  background-size: 20px 20px;
}

@media screen AND (max-width: 767px){
  .dflex {
    display: block;
  }


  #map {
    border: 2px solid #cccccc;
    height: 350px;
    width: 100%;
    position: sticky !important;
    top: 126px;
    z-index: 999;
  }

  .content {
    width: 100%;
    height: 100%;
  }

  .so-3 {
    margin-top: 1rem;
  }
}