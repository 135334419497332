
.date {
    background-color: #C8DEA5;
    padding: 3px 15px;
    font-size: 1.2rem;
    width: 100%;
    clear: both;
    display: inline-block;
    cursor: pointer;
}

.box {
    padding: 0;
}

.pharmacy {
    border-top: 1px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
}

.pharmacy:first-child {
    border-top: none;
}

.notdienst {
    color: red;
    margin-top: 4px;
}